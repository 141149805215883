import React from "react";
import {
  ApartmentOutlined,
  TeamOutlined,
  AppstoreAddOutlined,
  ShopOutlined,
  SettingOutlined,
  UnlockOutlined,
  HomeOutlined,
  TagOutlined,
  GitlabOutlined
} from "@ant-design/icons";
import Application from "../pages/Application";
import HierarchySetting from "../pages/HierarchySetting";
import Site from "../pages/Site";
import User from "../pages/User";
import Setting from "../pages/Setting";
import Groups from "../pages/Group";
import HierarchyTitle from "../pages/Entity";
import Home from "../pages/Home";
import GlobalUser from "../../../../public/global_users.svg";
import NormalUser from "../../../../public/normal_user.svg";
import HierarchyTreeIcon46 from "../../../../public/hierarchy_trees_icon_46.svg";
import AuthProviders from "../pages/AuthProviders";

const componentForResource = {
  hierarchySetting: HierarchySetting,
  hierarchyTitles: HierarchyTitle,
  user: User,
  site: Site,
  application: Application,
  groups: Groups,
  setting: Setting,
  home: Home,
  auth_providers: AuthProviders,
};

const getUserRoutes = (group, intl) => {
  const route = {
    pathname: "/users",
    icon: TeamOutlined,
    title: intl.formatMessage({ id: "navigation.users" }),
    component: componentForResource["user"],
    allowedRoles: ["user_read", "user_read_qualimetrie"]
  };

  if (group.roles.includes("user_read_qualimetrie") && !group.roles.includes("user_read")) {
    route.pathname = "/users/qualimetrie";
    route.title = intl.formatMessage({ id: "navigation.user.qualimetrie" });

    return route;
  }

  if (!group.roles.includes("user_read_qualimetrie") && group.roles.includes("user_read")) {
    return route;
  }

  route.submenu = [{
    pathname: "/users/normal",
    component: componentForResource["user"],
    title: intl.formatMessage({ id: "navigation.user.client" }),
    icon: TagOutlined
  }, {
    pathname: "/users/qualimetrie",
    component: componentForResource["user"],
    title: intl.formatMessage({ id: "navigation.user.qualimetrie" }),
    icon: () => <img src={GlobalUser} alt="global_user_icon" style={{ width: 20, height: 20, marginRight: 5}} />
  }];

  return route;
};

/**
 *
 * @param {string} pathname - page name (used to form the url)
 * @param {ant-design/icons} icon - icon to display inside a menu
 * @param {React.Component} component - component associated to the view/page
 * @param {boolean} [skipRoles] - indicates if we need to check the user roles to display that route
 * @param {string} [title] - override @pathname to display the title of the page
 * @param {array[string]} [allowedRoles] - allowed roles to access that page
 */
const routes = (group, intl) => [
  {
    pathname: "/",
    key: 'home',
    icon: HomeOutlined,
    component: componentForResource["home"],
    skipRoles: true,
    title: intl.formatMessage({ id: "navigation.home" }),
  },
  {
    pathname: "/hierarchy_trees",
    icon: {
      src: HierarchyTreeIcon46,
      width: 21,
      height: 40,
    },
    title: intl.formatMessage({ id: "navigation.hierarchy_trees" }),
    component: componentForResource["hierarchySetting"],
    allowedRoles: ["hierarchy_read"],
  },
  {
    pathname: "/hierarchy_titles",
    icon: ApartmentOutlined,
    title: intl.formatMessage({ id: "navigation.hierarchy_titles" }),
    component: componentForResource["hierarchyTitles"],
    allowedRoles: ["entity_read"],
  },
  {
    pathname: "/sites",
    icon: ShopOutlined,
    component: componentForResource["hierarchySetting"],
    title: intl.formatMessage({ id: "navigation.sites" }),
    allowedRoles: ["site_read"],
  },
  getUserRoutes(group, intl),
  {
    pathname: "/settings",
    icon: SettingOutlined,
    title: intl.formatMessage({ id: "navigation.settings" }),
    allowedRoles: ["group_setting_edit"],
    component: componentForResource["setting"],
  },
  {
    pathname: "/groups",
    icon: UnlockOutlined,
    title: intl.formatMessage({ id: "navigation.groups" }),
    component: componentForResource["groups"],
    allowedRoles: ["group_permission_read"]
  },
  {
    pathname: "/auth_providers",
    icon: GitlabOutlined,
    title: intl.formatMessage({ id: "navigation.auth_providers" }),
    component: componentForResource["auth_providers"],
    allowedRoles: ["group_manage_sso"],
    onlyInternal: true
  }
];

export default routes;