import React, { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import {
  Button,
  Card,
  PageHeader,
  Modal,
  Space,
} from "antd";
import qs from 'qs';
import { omit, pick } from 'lodash';
import { useLocation } from 'react-router-dom';
import { SyncOutlined, PlusOutlined, FileExcelOutlined } from '@ant-design/icons';
import { decamelizeKeys, camelizeKeys } from 'humps';
import { connect, useDispatch } from 'react-redux';
import {
  User as UserType,
  ApplicationType,
  HierarchyTree,
  Tenant,
  ExportFile,
  Group
} from '../../types';
import styles from './index.module.sass';
import memoOnlyForKeys from '../../utils/memoOnlyForKeys';
import client from '../../utils/client';
import UserList from './components/UserList';
import usePagination from '../../utils/usePagination';
import UserService from '../../services/user';
import UserEdit from './components/UserEdit';
import UserImporter from './components/UserImporter';
import useI18n from '../../utils/useI18n';
import { hasRole } from '../../utils/Authorization';
import PasswordEdit from './components/PasswordEdit';
import { useCurrentUser } from "./components/UserEdit/utils";
import { replace } from 'connected-react-router';

const getAllUser = async ({ queryKey: [key, page, pageSize, nameEmail, applicationId, invitationState, since, unsynced, part] }, format = "json") => {
  const search = {
    nameEmail,
    applicationId,
    invitationState,
    since,
    part,
    unsynced
  };
  const {
    response,
    params,
  } = await UserService.all(page, pageSize, search, format);
  return {
    data: response,
    params
  };
};

interface IProps {
  applications: ApplicationType[];
  visible: boolean;
  wizardSteps: string[];
  editingUser: UserType;
  editParams: any;
  editLoading: boolean;
  hierarchyTrees: HierarchyTree[];
  errors: { [key: string]: string[] };
  saveLoading: boolean;
  importerOpen: boolean;
  tenant: Tenant;
  importErrors: { email: string; messages: string; application: string }[];
  part: string;
  group: Group;
  passwordVisible: boolean;
  onEditCancel: () => void;
  onResendInvitations: (search) => void;
  onSubmit: (editingUser: UserType, options: any) => void;
  addExportFile: (file: ExportFile) => void;
  onPasswordSave: (editingUser: UserType) => void;
  onDelete: (id: string) => void;
  onImporterOpen: (open: boolean) => void;
  onImport: (file: any) => void;
  onPasswordEdit: (id: string) => void;
  onUserSynchronization: () => void;
  onEdit: (id?: string) => void;
  fetchApplications: () => void;
  fetchHierarchyTrees: () => void;
}

const onExport = (search, addExportFile, part) => {
  let queryString = `part=${part}&`;
  if (search.invitationState) {
    queryString += `invitation_state=${search.invitationState}&`;
  }
  if (search.since) {
    queryString += `since=${search.since}&`;
  }
  if (search.nameEmail) {
    queryString += `name_email=${search.nameEmail}&`;
  }
  if (search.applicationId) {
    queryString += `application_id=${search.applicationId}&`;
  }
  const endpoint = `/users/export.xlsx?${queryString}`;
  //@ts-ignore
  client.get(endpoint).then(({ exportFile }) => addExportFile(exportFile));
};

type UserSearch = {
  nameEmail?: string;
  applicationId?: string;
  invitationState?: string;
  since?: string;
  part?: string;
  unsynced?: boolean;
};

const searchFields = ["name_email", "application_id", "invitation_state", "since", "part", "unsynced"];

const User: React.FC<IProps> = props => {
  const {
    applications,
    visible,
    editingUser,
    wizardSteps,
    editParams,
    editLoading,
    hierarchyTrees,
    errors,
    saveLoading,
    importerOpen,
    tenant,
    importErrors,
    part,
    group,
    passwordVisible,
    fetchApplications,
    onEdit,
    onEditCancel,
    fetchHierarchyTrees,
    onDelete,
    onResendInvitations,
    onImporterOpen,
    onImport,
    onUserSynchronization,
    addExportFile,
    onPasswordEdit,
    onPasswordSave,
  } = props;

  useEffect(() => {
    fetchApplications();
    fetchHierarchyTrees();
  }, []);

  const dispatch = useDispatch();

  const location = useLocation();
  const urlParams = qs.parse(location?.search, { ignoreQueryPrefix: true });
  const { t } = useI18n();

  const {
    onChangePagination,
    pagination,
  } = usePagination({ page: parseInt(urlParams.page || 1), pageSize: parseInt(urlParams.page_size || 30) });

  const [search, setSearch] = useState<UserSearch>(
    camelizeKeys(pick(urlParams || {}, searchFields)) as UserSearch
  );

  const onSuccessFullFetch = useCallback(({ data: { pagination }, params }) => {
      const cleanedParams = decamelizeKeys(omit(params, 'part'));
      const stringifiedParams = qs.stringify({
        ...(urlParams || {}),
        ...cleanedParams,
      }, {
        arrayFormat: "brackets",
        encode: false
      })
      dispatch(replace(`${location?.pathname}?${stringifiedParams}`))
      onChangePagination(pagination)
    }, [onChangePagination, urlParams, location?.pathname]);

  const {
    isLoading: loading,
    data: {
      // @ts-ignore
      data,
      // @ts-ignore
      params
    } = {},
    refetch
  } = useQuery({
    queryKey: ['users', pagination.page, pagination.pageSize, search.nameEmail, search.applicationId, search.invitationState, search.since, search.unsynced, part],
    queryFn: getAllUser,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    onSuccess: onSuccessFullFetch
  });

  const onSubmit = (values: UserType)  => props.onSubmit(values, { onCompleted: refetch })

  const currentUser = useCurrentUser();
  if (!currentUser) return null;

  const editingRoles = ["user_qualimetrie", "user_network_manager"];
  applications.forEach(app => editingRoles.push(`user_application_${app.id}`));

  const onSynchronize = () => Modal.confirm({
    title: t('user.sync'),
    onOk: () => {
      onUserSynchronization();
    },
    okText: "Synchroniser",
    cancelText: t('words.cancel'),
  });

  const onSearch = (search) => {
    setSearch(search);
    onChangePagination({ page: 1, pageSize: pagination.pageSize });
  }

  return (
    <>
      {hasRole(group, ["user_import"]) && importerOpen && (
        <UserImporter
          importerOpen={importerOpen}
          loading={editLoading}
          onImporterOpen={onImporterOpen}
          importErrors={importErrors}
          onImport={onImport}
        />
      )}
      <PageHeader
        style={{ marginBottom: 16 }}
        className={styles.pageHeader} title={t(`user.header_${part}`)} 
        extra={[
          hasRole(group, ["user_import", "user_create"]) && (
            <Button
              onClick={onSynchronize}
              icon={<SyncOutlined />}
            >
              {t("synchronization.user")}
            </Button>
          ),
          <Button
            icon={<FileExcelOutlined />}
            onClick={() => onExport(search, addExportFile, part)}
          >
            {t("user.export")}
          </Button>,
          hasRole(group, ["user_import"]) && (
            <Button
              icon={<FileExcelOutlined />}
              onClick={() => onImporterOpen(!importerOpen)}
              type="primary"
            >
              {t("user.import")}
            </Button>
          ),
          hasRole(group, editingRoles) && (
            <Button
              icon={<PlusOutlined />}
              onClick={() => onEdit()}
              type="primary"
            >
              {t("user.new")}
            </Button>
          )
        ]}
      />
      <Card style={{ position: "relative" }} className={styles.siteListWrapper}>
        <UserList
          key="user-list"
          loading={loading}
          users={data?.users || []}
          setSearch={onSearch}
          pagination={pagination}
          search={search}
          onEdit={onEdit}
          applications={applications}
          setPagination={onChangePagination}
          currentUser={currentUser}
          group={group}
          onResendInvitations={onResendInvitations}
          editingRoles={editingRoles}
          onPasswordEdit={onPasswordEdit}
          part={part}
        />
      </Card>
      {hasRole(group, editingRoles) && visible && (
        <UserEdit
          key="user-editing-modal"
          hierarchyTrees={hierarchyTrees}
          editLoading={editLoading}
          visible={visible}
          onCancel={onEditCancel}
          user={editingUser}
          group={group}
          wizardSteps={wizardSteps}
          applications={applications}
          editParams={editParams}
          onSubmit={onSubmit}
          errors={errors}
          onEdit={onEdit}
          currentUser={currentUser}
          onDelete={onDelete}
          saveLoading={saveLoading}
          tenantLocales={tenant.availableLocales}
          authProviders={tenant.authProviders}
          hasApiKey={tenant.hasApiKey}
        />
      )}
      {hasRole(group, ["user_update_password"]) && passwordVisible && (
        <PasswordEdit
          user={editingUser}
          onPasswordSave={onPasswordSave}
          onCancel={onEditCancel}
          passwordVisible={passwordVisible}
          editLoading={editLoading}
          saveLoading={saveLoading}
          errors={errors}
        />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  users: state.users.list,
  pagination: state.users.pagination,
  visible: state.users.open,
  editingUser: state.users.current,
  errors: state.users.errors,
  wizardSteps: state.users.wizardSteps,
  applications: state.applications.list,
  editParams: state.users.editParams,
  editLoading: state.users.loading,
  hierarchyTrees: state.hierarchyTrees.list,
  saveLoading: state.users.saveLoading,
  importerOpen: state.users.importerOpen,
  tenant: state.tenants.current,
  importErrors: state.users.importErrors,
  passwordVisible: state.users.passwordVisible,
  group: state.group.current,
});

const mapDispatchToProps = dispatch => ({
  onEditCancel: () => dispatch({ type: "user/cancelEdit" }),
  onUserSynchronization: () => dispatch({ type: "user/sync" }),
  fetchApplications: () => dispatch({ type: "application/fetch" }),
  fetchHierarchyTrees: () => dispatch({ type: "hierarchyTree/fetch" }),
  onEdit: (id?: string) => dispatch({ type: "user/show", payload: { id } }),
  onPasswordEdit: (id?: string) => dispatch({ type: "user/editPassword", payload: { id } }),
  onSubmit: (editingUser, { onCompleted } = { onCompleted: undefined }) => {
    dispatch({
      type: "user/save",
      payload: editingUser, 
      options: {
        onCompleted
      },
    });
  },
  onPasswordSave: (editingUser) => dispatch({ type: "user/updatePassword", payload: editingUser }),
  onDelete: (id) => {
    dispatch({ type: "user/delete", payload: id });
  },
  onResendInvitations: (search) => {
    dispatch({ type: "user/resendInvitations", payload: search });
  },
  onImporterOpen: (open) => {
    dispatch({ type: "user/setImporterOpen", payload: open });
  },
  onImport: (file) => {
    dispatch({ type: 'user/importUsers', payload: file });
  },
  addExportFile: (file) => {
    dispatch({ type: 'exportFiles/addToList', payload: file });
  }
});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(User), memoOnlyForKeys(["users", "editLoading", "visible", 'importerOpen', 'passwordVisible', 'part']));